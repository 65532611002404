import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsappQRComponent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const tokenKey = 'whatsappModalToken'; // Replace with your desired token key

  // Check sessionStorage for the token when component mounts
  useEffect(() => {
    const tokenExists = sessionStorage.getItem(tokenKey);
    if (!tokenExists) {
      setIsModalVisible(true); // Show modal if token doesn't exist
    }
  }, []);

  // Handle modal close and set token in sessionStorage
  const handleClose = () => {
    sessionStorage.setItem(tokenKey, 'true'); // Set your token value
    setIsModalVisible(false);
  };

  if (!isModalVisible) return null; // Don't render modal if it's not visible

  return (
    <div className="p-4 bg-white rounded-xl shadow-md flex items-center space-x-4 fixed bottom-5 right-5 z-20 max-w-[600px]">
      <div className="flex-shrink-0">
        <img
          src="https://cdn.leafround.com/promo/QR-partner_whatsapp_community.png"
          alt="QR Code"
          className="w-40 h-40"
        />
      </div>
      <div>
        <div className="flex items-center justify-between">
          <WhatsAppIcon className="text-green-500" />
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <p className="text-gray-700 text-lg font-semibold">
          Join our community for all Tap Partners at the link below:
        </p>
        <a
          href="https://chat.whatsapp.com/C2KILPLCY629K8Nfofa2pK"
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-500 underline"
        >
          Click here to join
        </a>
        <p className="text-gray-500">You can also scan the code to join!</p>
      </div>
    </div>
  );
};

export default WhatsappQRComponent;
