import { create } from 'zustand';

export type ClientDetails = {
  pan?: string;
  name?: string;
  email?: string;
};

interface LayoutStore {
  isLeftNavOpen: boolean;
  isClientDrawerOpen: boolean;
  isReinvestModalOpen: boolean;
  reinvestDeal: any;
  clientId: string | null;
  clientDetails?: ClientDetails;
  partnerId?: string | null;
  isPartnerDrawerOpen: boolean;
  partnerDetails?: any;
  familyAccountID?: string | null;
  familyAccountDetails?: any;
  isFamilyAccountDrawerOpen: boolean;
  setIsLeftOpen: () => void;
  setClientId: (clientId: string | null) => void;
  setReinvestDeal: (reinvestDeal: any) => void;
  setClientDetails: (clientDetails) => void;
  setIsClientDrawerOpen: (clientId?: string | null) => void;
  setPartnerDetails: (partnerDetails) => void;
  setIsPartnerDrawerOpen: (partnerId?: string | null) => void;
  setIsReinvestModalOpen: (isReinvestModalOpen: boolean) => void;
  setPartnerId: (partnerId: string | null) => void;
  setFamilyAccountID: (familyAccountID: string | null) => void;
  setFamilyAccountDetails: (familyAccountDetails) => void;
  setIsFamilyAccountDrawerOpen: (familyAccountID?: string | null) => void;
}
export const useLayoutStore = create<LayoutStore>((set) => ({
  isLeftNavOpen: false,
  isClientDrawerOpen: false,
  isReinvestModalOpen: false,
  clientId: null,
  reinvestDeal: null,
  isPartnerDrawerOpen: false,
  partnerId: null,
  familyAccountID: null,
  familyAccountDetails: null,
  isFamilyAccountDrawerOpen: false,
  partnerDetails: null,
  setIsLeftOpen: () => {
    return set((state) => ({ isLeftNavOpen: !state.isLeftNavOpen }));
  },
  setIsClientDrawerOpen: (clientId?: string | null) => {
    // Clear client details when drawer is closed
    return set((state) => ({
      isClientDrawerOpen: !state.isClientDrawerOpen,
      clientId,
      clientDetails: clientId ? state.clientDetails : undefined,
    }));
  },
  setClientId: (clientId: string | null) => {
    return set((state) => ({ ...state, clientId }));
  },
  setClientDetails: (clientDetails: ClientDetails) => {
    return set((state) => ({
      ...state,
      clientDetails: { ...state.clientDetails, ...clientDetails },
    }));
  },
  setIsPartnerDrawerOpen: (partnerId?: string | null) => {
    return set((state) => ({
      isPartnerDrawerOpen: !state.isPartnerDrawerOpen,
      partnerId,
      partnerDetails: partnerId ? state.partnerDetails : undefined,
    }));
  },
  setPartnerId: (partnerId: string | null) => {
    return set((state) => ({ ...state, partnerId }));
  },
  setPartnerDetails: (partnerDetails) => {
    return set((state) => ({
      ...state,
      partnerDetails: { ...state.partnerDetails, ...partnerDetails },
    }));
  },
  setFamilyAccountID: (familyAccountID: string | null) => {
    return set((state) => ({ ...state, familyAccountID }));
  },
  setIsFamilyAccountDrawerOpen: (familyAccountID?: string | null) => {
    return set((state) => ({
      isFamilyAccountDrawerOpen: !state.isFamilyAccountDrawerOpen,
      familyAccountID,
      partnerDetails: familyAccountID ? state.partnerDetails : undefined,
    }));
  },
  setFamilyAccountDetails: (familyAccountDetails) => {
    return set((state) => ({
      ...state,
      familyAccountDetails: { ...state.familyAccountDetails, ...familyAccountDetails },
    }));
  },
  setIsReinvestModalOpen: (isReinvestModalOpen: boolean) => {
    return set({ isReinvestModalOpen });
  },
  setReinvestDeal: (reinvestDeal: any) => {
    return set({ reinvestDeal });
  },
}));
