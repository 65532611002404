export const GOLD_FAQS = [
  {
    id: 0,
    question: 'What is Digital Gold?',
    answer: `Digital Gold offers a way to invest in gold online without the need for physical ownership. It's a secure and reliable investment option, allowing investors to buy, sell, and store gold in digital form.
    
    We've partnered with Augmont, India's largest fully integrated gold player, encompassing refining to retailing, to bring Digital Gold as an investment option.`,
  },
  {
    id: 1,
    question: 'What is the purity of the Gold we offer?',
    answer:
      'We offer 24 Karat 999 Gold, which is the highest purity level for gold available in the market. This means our gold is 99.9% pure, ensuring you receive the finest quality gold for your investment.',
  },
  {
    id: 2,
    question: 'How do we ensure fair & competitive prices?',
    answer:
      'Our Buy and Sell rates are set to the best prices by using wholesale market prices on a real time basis. Augmont, our partner, gets gold at wholesale prices and passes those savings to you. This means you get a better deal compared to many others out there as we constantly monitor the market to keep our prices competitive and fair.',
  },
  {
    id: 3,
    question: 'What is the tax rate for Long-Term Capital Gains (LTCG) on gold holdings?',
    answer:
      'If you hold gold for more than 24 months, you are liable to pay a 12.5% Long-Term Capital Gains (LTCG) tax, calculated after indexation. Indexation helps adjust the purchase price of your gold for inflation, reducing the taxable gain.',
  },
  {
    id: 4,
    question: 'What is the tax rate for Short-Term Capital Gains (STCG) on gold holdings?',
    answer:
      "If you hold gold for less than 24 months, you'll need to pay Short-Term Capital Gains (STCG) tax based on your income tax slab rate.",
  },
  {
    id: 5,
    question: 'Why is there a difference between the Buy & Sell price?',
    answer: (
      <div>
        The buy and sell prices for Gold/Silver on our platform are displayed transparently and
        reflect the current market rates. However, there is always a slight difference between these
        prices due to several factors:
        <ul className="list-disc pl-4 my-1">
          <li>
            <strong>Price Volatility</strong>: Fluctuations in the market can cause the buy and sell
            prices to vary.
          </li>
          <li>
            <strong>Supply and Demand</strong>: The availability of gold and silver affects the
            pricing spread.
          </li>
          <li>
            <strong>External Market Conditions</strong>: Economic factors, geopolitical events, and
            other market dynamics can impact prices.
          </li>
        </ul>
        Additionally, GST is applied to the buy price but not the sell price, and other charges such
        as those for payment gateways, trustees, and service fees also contribute to the difference
        between the buy and sell prices.
      </div>
    ),
  },
  {
    id: 6,
    question: 'Where will the money be credited?',
    answer: "The money will be credited to the client's bank account.",
  },
  {
    id: 7,
    question: 'Where is the Physical Gold/Silver stored?',
    answer:
      "The physical gold is stored securely in Sequel's vaults. Sequel is known for its high-security standards, ensuring the utmost safety for your precious metals. Additionally, the gold stored in these vaults is fully covered by insurance, providing an extra layer of protection for your investment.",
  },
  {
    id: 8,
    question: 'Who is Sequel?',
    answer:
      "Sequel Logistics is a global leader in security-related services. They cater to a wide range of industries, including banks, financial institutions, mines, retailers, diamond and jewellery sectors, pharmaceuticals, and various commercial and governmental clients. Sequel's expertise ensures the utmost safety and security for all their customers' valuable assets.",
  },
  {
    id: 7,
    question: "What is the role of the 'Independent Trustee?'",
    answer:
      'Valgo Retail Services Private Limited, formerly known as Valgo Finsec Services Private Limited and managed by the SEQUEL group, serves as an Independent Trustee. Their role is to act on behalf of clients and protect their interests. When you purchase bullion (i.e., gold), Valgo has the first and exclusive charge over it. After you accept the Terms and Conditions, Augmont sends your physical bullion to a secured vault, which is then verified by the Independent Trustee to ensure its security and authenticity.',
  },
  {
    id: 8,
    question: 'Who is insuring the physical Gold?',
    answer:
      'A top insurance firm provides comprehensive insurance for the physical gold stored in our vaults. This insurance covers not only the gold housed in the vaults but also the gold in transit at the time of delivery. This ensures that your investment is protected at all times.',
  },
];

export const GOLD_EVENTS = {
  GOLD: 'Clicked_Gold_tab',
  BUY_GOLD: 'Clicked_Gold_buy_Detail_page',
  SELL_GOLD: 'Clicked_Gold_Sell_Detail_page',
  SELECT_CLIENT: 'Gold_sell_select_client',
  GENERATE_PAYMENT_LINK: 'Gold_Generate_Payment_Link',
  ENTER_GOLD_AMOUNT: 'Gold_Enter_Amount',

  // BUY_GOLD_SUCCESS: 'BUY_GOLD_SUCCESS',
  // SELL_GOLD_SUCCESS: 'SELL_GOLD_SUCCESS',
  // BUY_GOLD_FAILURE: 'BUY_FAILURE',
  // IN_RUPPEES_GOLD: 'IN_RUPPEES_GOLD',
  // IN_GRAMS_GOLD: 'IN_GRAMS_GOLD',
  // USER_GOLD_SELECT: 'USER_GOLD_SELECT',
};
