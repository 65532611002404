import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { CloseOutlinedIcon, HelpOutlineOutlinedIcon } from '../../../../assets/mui';

const AddSingleAndBulkUser = ({ onClose, open, handleAddNewUser, handleAddBulkUser, userText }) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px !important',

        },
      }}
    >
      <DialogTitle>
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg font-semibold">Choose {userText} addition type:</h3>
          </div>
          <CloseOutlinedIcon
            className="cursor-pointer bg-slate-200 rounded-full p-1"
            onClick={onClose}
            style={{
              width: '28px',
              height: '28px',
            }}
          />
        </div>
      </DialogTitle>

      <DialogContent sx={{ p: 1 }}>
        <div className="bg-white rounded-lg">
          {/* Add Single Client Section */}
          <div className="flex items-center justify-between px-4 py-3 border-b">
            <div className="py-2">
              <h4 className="text-base font-semibold text-gray-800">Add a single {userText}</h4>
              <p className="text-sm text-gray-500">
                Proceed with this option to add a single {userText}
              </p>
            </div>
            <button
              className="bg-green-700 text-white py-2 px-4 rounded-lg font-semibold hover:bg-green-800 transition-colors"
              onClick={handleAddNewUser}
            >
              Add {userText}
            </button>
          </div>

          {/* Add Bulk Clients Section */}
          <div className="flex items-center justify-between px-4 py-3">
            <div className="py-2">
              <h4 className="text-base font-semibold text-gray-800">Add bulk {userText}s</h4>
              <p className="text-sm text-gray-500">
                Proceed with this option to add multiple {userText}s using a CSV file
              </p>
            </div>
            <button
              className="bg-green-700 text-white py-2 px-4 rounded-lg font-semibold hover:bg-green-800 transition-colors"
              onClick={handleAddBulkUser}
            >
              Upload CSV
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddSingleAndBulkUser;
