import { useState } from 'react';

export const SecurityMeasureCard = ({
  Icon,
  tooltipTitle,
  tooltipContent,
  isMultipleLines = false,
}) => {
  const [readMore, setReadMore] = useState(false);

  return (
    <div className="flex flex-col items-start p-6 border border-gray-300 bg-transparent rounded-md">
      <Icon
        style={{
          backgroundColor: 'rgba(21, 128, 61, 0.06)',
          borderRadius: '12px',
          padding: '12px',
          marginBottom: '12px',
          width: '48px',
          height: '48px',
          color: 'rgb(21, 128, 61)',
        }}
      />
      <div className="flex flex-col gap-3">
        <div className="text-base font-medium">{tooltipTitle}</div>
        <div className="text-sm text-gray-500">
          {readMore
            ? isMultipleLines
              ? tooltipContent?.split('\n')?.map((content, index) => (
                  <p key={index} className="text-sm text-gray-500">
                    {content.replace('\n', '')}
                    <br />
                    <br />
                  </p>
                ))
              : tooltipContent
            : tooltipContent.split(' ').slice(0, 16).join(' ') + '...'}
        </div>
        <button
          className="text-sm text-green-800 font-semibold underline cursor-pointer"
          onClick={() => setReadMore((prev) => !prev)}
        >
          {`Read ${readMore ? 'less' : 'more'}`}
        </button>
      </div>
    </div>
  );
};
