import React, { useState } from "react";
import Input from "../../../components/Fields/Input";
import { Field } from "../../../components/Fields/Field";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NetworkManager } from "../../../services";
import { kycError } from "../../../utils/toast";
import KYCButton from "../../Onboarding/KYCButton";
import { useLayoutStore } from "../../../components/organisms/Layout/store";
import { PartnerOnboardingActions } from "../types";
import { PARTNERS_EVENTS, PARTNER_ONBOARDING_PAGE_TYPE } from "../constants";
import toast from "react-hot-toast";
import { useAnalytics } from "../../../hooks/useAnalytics";

interface BankVerificationProps extends PartnerOnboardingActions { }

const BankVerification: React.FC<BankVerificationProps> = ({ handleStep }) => {
  const { partnerId } = useLayoutStore(({ partnerId }) => ({ partnerId }));
  const [isLoading, setIsLoading] = useState(false);

  const { track } = useAnalytics();

  const schema = yup.object().shape({
    accountNumber: yup
      .string()
      .required("Account number is required")
      .matches(/^\d{9,20}$/, "Invalid account number format"), // Matches 9 to 20 digits
    verifyAccountNumber: yup
      .string()
      .required("Account number re-verify required")
      .oneOf([yup.ref("accountNumber"), null], "Account numbers do not match"), // Must match the value of accountNumber
    ifscCode: yup
      .string()
      .required("IFSC Code is required")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format"), // Matches IFSC code format
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      accountNumber: null,
      verifyAccountNumber: null,
      ifscCode: null,
    },
  });

  const confirmBank = (accountNumber: string, ifscCode: string) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.partnerVerifyBankInfo,
      data: {
        partnerId,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
      },
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { accountNumber, ifscCode } = data;

    setIsLoading(true);

    try {
      const bankRes = await confirmBank(accountNumber, ifscCode);
      handleStep(PARTNER_ONBOARDING_PAGE_TYPE.FINAL_STEP);
      handleEntryEvent(PARTNERS_EVENTS.KYC_Success_Screen);
    } catch (error) {
      if (error?.response?.status.toString().includes("5")) {
        toast.error("Something went wrong! Please try again", {
          duration: 2500,
          position: "top-center",
        });
      } else if (error?.response?.status.toString().includes("4")) {
        toast.error(error?.response?.data.message, {
          duration: 2500,
          position: "top-center",
        });
      } else {
        kycError("Something went wrong! Please try again");
      }
    }

    setIsLoading(false);
  };

  const handleEntryEvent = (event) => {
    track({ eventName: event });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col max-w-96">
      <Field label="Account Number" error={errors.accountNumber?.message}>
        <Input
          onClick={() =>
            handleEntryEvent(PARTNERS_EVENTS.KYC_Bank_Account_Entry)
          }
          type="password"
          placeholder="Enter Account Number"
          numericOnly={true}
          {...register("accountNumber", { required: true, maxLength: 80 })}
        />
      </Field>
      <Field
        label="Confirm Account Number"
        className="mt-6"
        error={errors.verifyAccountNumber?.message}
      >
        <Input
          onClick={() =>
            handleEntryEvent(PARTNERS_EVENTS.KYC_Bank_Confirm_Account)
          }
          type="text"
          numericOnly={true}
          placeholder="Re-enter Account Number"
          {...register("verifyAccountNumber", {
            required: true,
            maxLength: 80,
          })}
        />
      </Field>
      <Field
        label="IFSC Code"
        className="mt-6"
        error={errors.ifscCode?.message}
      >
        <Input
          onClick={() => handleEntryEvent(PARTNERS_EVENTS.KYC_Bank_IFSC_Entry)}
          type="text"
          placeholder="Enter IFSC Code"
          {...register("ifscCode", { required: true, maxLength: 80 })}
        />
      </Field>

      <KYCButton isLoading={isLoading} text="Verify Details" />
    </form>
  );
};

BankVerification.displayName = "BankVerification";

export default BankVerification;
