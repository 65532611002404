export const PARTNER_ONBOARDING_PAGE_TYPE = {
    FIRST_STEP: "FIRST_STEP",
    IDENTITY_VERIFICATION: "IDENTITY_VERIFICATION",
    PAN_VERIFICATION: "PAN_VERIFICATION",
    AADHAAR_VERIFICATION: "AADHAAR_VERIFICATION",
    BANK_VERIFICATION: "BANK_VERIFICATION",
    FINAL_STEP: "FINAL_STEP",
} as const;

export const PARTNER_ONBOARDING_STEPS = {
  [PARTNER_ONBOARDING_PAGE_TYPE.IDENTITY_VERIFICATION]: {
    stepTitle: 'Identity Verification',
    verifiedKey: 'isIdentityVerified',
    nextStep: PARTNER_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION,
    previousStep: PARTNER_ONBOARDING_PAGE_TYPE.FIRST_STEP,
    nextStepTitle: 'Pan Details',
    stepDescription: 'Your partners email & phone number',
    formDescription: 'Enter phone number and email address of the partner you wish to add.',
  },
  [PARTNER_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION]: {
    stepTitle: 'PAN Verification',
    verifiedKey: 'isPanVerified',
    nextStep: PARTNER_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION,
    previousStep: PARTNER_ONBOARDING_PAGE_TYPE.IDENTITY_VERIFICATION,
    nextStepTitle: 'Aadhaar',
    stepDescription: 'This is needed as per Govt. of India’s guidelines.',
    formDescription:
      "Enter the partner's PAN Number that is linked to their Aadhaar to successfully complete the KYC process.",
  },
  [PARTNER_ONBOARDING_PAGE_TYPE.AADHAAR_VERIFICATION]: {
    stepTitle: 'Aadhaar Verification',
    verifiedKey: 'isAadhaarVerified',
    nextStep: PARTNER_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION,
    previousStep: PARTNER_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION,
    nextStepTitle: 'Bank Details',
    stepDescription: 'This is needed as per Govt. of India’s guidelines.',
    formDescription:
      "Enter the partner's Aadhaar Number or upload Aadhaar that is linked to their PAN Number to successfully complete the KYC process.",
  },
  [PARTNER_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION]: {
    stepTitle: 'Bank Verification',
    verifiedKey: 'isBankVerified',
    previousStep: PARTNER_ONBOARDING_PAGE_TYPE.AADHAAR_VERIFICATION,
    nextStepTitle: 'All Done',
    stepDescription: 'The details of the bank you will be investing from, and receive payouts.',
    formDescription:
      'Please provide the bank details associated with the account you wish to invest with and receive payouts to.',
  },
};

export const SORTABLE_COLUMNS = {
    name: "name",
    email: "email",
    phone: "phone",
    pan: "pan",
    amount: "investedAmount",
    balance: "walletBalance",
};

export const PARTNER_SORTABLE_COLUMNS = {
    name: "firstName",
    email: "email",
    phone: "phone",
};

export const PARTNERS_EVENTS = {
    Add_new_Partner: "Add_new_Partner",
    Identity_Verification_Initiated: "Partner_Identity_Verification_Initiated",
    FName_Entry: "Partner_FName_Entry",
    LName_Entry: "Partner_LName_Entry",
    Email_Entry: "Partner_Email_Entry",
    Phone_Entry: "Partner_Phone_Entry",
    Identity_Verification_Success: "Partner_Identity_Verification_Success",
    KYC_PAN_Initiated: "Partner_KYC_PAN_Initiated",
    KYC_PAN_Entry: "Partner_KYC_PAN_Entry",
    KYC_PAN_Verify: "Partner_KYC_PAN_Verify",
    KYC_Aadhaar_Initiated: "Partner_KYC_Aadhaar_Initiated",
    KYC_Aadhaar_Entry: "Partner_KYC_Aadhaar_Entry",
    KYC_Aadhaar_Request_OTP: "Partner_KYC_Aadhaar_Request_OTP",
    KYC_Aadhaar_OTP_Entry: "Partner_KYC_Aadhaar_OTP_Entry",
    KYC_Bank_Initiated: "Partner_KYC_Bank_Initiated",
    KYC_Bank_Account_Entry: "Partner_KYC_Bank_Account_Entry",
    KYC_Bank_Confirm_Account: "Partner_KYC_Bank_Confirm_Account",
    KYC_Bank_IFSC_Entry: "Partner_KYC_Bank_IFSC_Entry",
    KYC_Success_Screen: "Partner_KYC_Success_Screen",
    KYC_Skip: "Partner_KYC_Skip",
};
